import { apiClient } from '@api/client';
import { AuthorizationBoundary } from '@components/AuthorizationBoundary';
import { ErrorPage500 } from '@pages/ErrorPage';
import { useMutation } from '@tanstack/react-query';
import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface MondayOAuthInnerProps {
  code: string;
  state: string;
  loader: ReactElement;
}

function MondayOAuthInner({ code, state, loader }: MondayOAuthInnerProps) {
  const { mutate, isError } = useMutation({
    mutationFn: async () => {
      const { data } = await apiClient.monday.mondayControllerConnect({
        code,
        state
      });
      return data;
    },
    onSuccess: (data) => {
      window.location.href = data.backToUrl;
    }
  });
  useEffect(() => {
    mutate();
  }, [mutate]);
  if (isError) {
    return <ErrorPage500 />;
  }
  return loader;
}

interface MondayOAuthProps {
  loader: ReactElement;
}

export default function MondayOAuth({ loader }: MondayOAuthProps) {
  const [searchParams] = useSearchParams();
  if (searchParams.get('code') || searchParams.get('state')) {
    // Auth0 uses 'code' and 'state' query parameters for its own auth flow
    // We need to rename code and state returned by Monday to avoid conflicts
    searchParams.set('_code', searchParams.get('code') ?? '');
    searchParams.set('_state', searchParams.get('state') ?? '');
    searchParams.delete('code');
    searchParams.delete('state');
    window.location.search = searchParams.toString();
    return loader;
  }
  const code = searchParams.get('_code') ?? '';
  const state = searchParams.get('_state') ?? '';
  return (
    <AuthorizationBoundary
      loader={loader}
      element={<MondayOAuthInner loader={loader} code={code} state={state} />}
      renderError={() => <ErrorPage500 />}
    />
  );
}
